import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './index.css'
import Page from "./components/Page";

const App = () => {
  const [currentPage, setCurrentPage] = useState("/");
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setCurrentPage(pathname || "/");
  }, [pathname]);

  return (
    <>
      <div>
        {/*TODO make stuff from page here*/}
        <div className="hidden lg:block">
          {/*<Sidebar>*/}
            <Page page={currentPage} />
          {/*</Sidebar>*/}
        </div>
        <div className="block lg:hidden">
          <Page page={currentPage} />
        </div>
      </div>
    </>
  );
};

export default App;
