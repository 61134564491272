import React from 'react';
import ReactDOM from 'react-dom/client';
import "reflect-metadata";
import './index.css';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";
import {plainToClass} from "class-transformer";

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element')
const root = ReactDOM.createRoot(rootElement);

let s = `/app_config/${window.location.origin.replace("https://", "")}`
if (window.location.origin.includes("localhost")) {
    s = "https://bavet.superhost.app/app_config/bavet.superhost.app"
}

// TODO move
class ConfigResponse {
    domain: string;
    client_id: string;
    audience: string;

    constructor(domain: string, client_id: string, audience: string) {
        this.domain = domain
        this.client_id = client_id
        this.audience = audience
    }
}

window.fetch(s, {
    method: 'GET',
}).then((response) => plainToClass(ConfigResponse, response.json()))
    .then((data) => {
        root.render(
            <React.StrictMode>
                <Auth0Provider
                    domain={data.domain}
                    clientId={data.client_id}
                    authorizationParams={{
                        redirect_uri: window.location.origin,
                        audience: data.audience
                    }}
                >
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </Auth0Provider>
            </React.StrictMode>
        )
    }
);
