import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Arrow from "./Arrow";
import {DashboardCategoryConfig} from "../model/dashboard";
import "./pagelist.css"

interface PageListArgs {
  categories: DashboardCategoryConfig[]
  textColor: string
}

export default function PageList(args: PageListArgs) {
  const location = useLocation();
  const currentPath = location.pathname.slice(1);

  const [openMenuPath, setOpenMenuPath] = useState<string | null>(null);

  function toggleSubmenu(path: string) {
    setOpenMenuPath((prevId) => (prevId === path ? null : path));
  }

  return (
    <div className="left-padding-menu z-50 w-full !h-screen max-h-screen lg:mb-10 lg:h-[75vh] px-5 lg:px-0 lg:overflow-y-scroll">
      <div className="grid grid-cols-1 sm:grid-cols-2 bg-[#f2f2f2] md:grid-cols-3 lg:grid-cols-1 sm:gap-x-3 pt-3">
        {args.categories.map((category) => (
          <div
            key={category.title}
            className="mb-4 lg:mb-0 rounded-lg lg:rounded-none p-3 lg:p-0 lg:bg-transparent h-min"
          >
            <div
              className={`flex items-center gap-3 lg:gap-1 group cursor-pointer ${
                category.path === currentPath ? "font-semibold" : "font-normal"
              }`}
              onClick={() => toggleSubmenu(category.path)}
            >
              <h3 className={`relative font-bold pb-1 text-[17px] ${
                  category.path === currentPath ||
                  (category.pages &&
                    category.pages.some(
                      (page) => `/${category.path}/${page.slug}` === currentPath
                    ))
                    ? ""
                    : "before:scale-x-0 before:hover:scale-x-100"
                }`}
                  style={{color: args.textColor}}
              >
                {category.title}
              </h3>
              {category.pages && (
                <span>
                  {openMenuPath === category.path ? (
                    <Arrow direction={"rotate-90"} />
                  ) : (
                    <Arrow direction={"rotate-0"} />
                  )}
                </span>
              )}
            </div>

            {category.pages && openMenuPath === category.path && (
              <ul className="pb-4">
                {category.pages && openMenuPath === category.path && (
                  <ul className="pb-4">
                    {category.pages.map((page) => (
                      <li key={page.title}>
                        <Link
                          to={ category.path + "/" + page.slug}  // TODO
                          className={`relative before:absolute before:block before:w-full before:h-[1.5px] 
          before:bottom-0 before:left-1/2 before:transform before:-translate-x-1/2
          before:ease-in-out before:duration-300 before:origin-center
          ${
            `${category.path}/${page.slug}` === currentPath // TODO
              ? ""
              : "before:scale-x-0 before:hover:scale-x-100"
          }              
          text-[15px] `}
                          style={{color: args.textColor}}
                        >
                          {page.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}