import {DashboardCategoryConfig} from "./dashboard";
import {Type} from "class-transformer";
import {BrandingConfig} from "./Branding";

export class AuthCallbackResponse {
    @Type(() => Date)
    expiry: Date

    auth_response: string
    key: string
    token: string
    categories: DashboardCategoryConfig[]
    role: string
    branding: BrandingConfig

    constructor(auth_response: string, key: string, token: string, categories: DashboardCategoryConfig[], role: string, expiry: Date, branding: BrandingConfig) {
         this.auth_response = auth_response;
         this.key = key;
         this.token = token;
         this.categories = categories;
         this.role = role;
         this.expiry = expiry
         this.branding = branding
    }
}