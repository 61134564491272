import React, {useState} from "react";
import { Link } from "react-router-dom";
import PageList from "../pagelist/PageList";
import ToggleLogos from "../ToggleLogos";
import {useAuth0} from "@auth0/auth0-react";
import "./sidebar.css";
import {DashboardCategoryConfig} from "../model/dashboard";


interface SidebarArgs {
  children: JSX.Element | JSX.Element[];
  categories: DashboardCategoryConfig[];
  longLogoUrl: string;
  squareLogoUrl: string;
  textColour: string;
}


function Sidebar(args: SidebarArgs) {
  const [isOpen, setIsOpen] = useState(true);
  const { user, logout } = useAuth0();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        className={`fixed flex flex-col h-screen py-3 pl-4 ${
          isOpen ? "w-40" : "w-20"
        }`}
      >
        {/* Sidebar */}
        <div className="flex flex-col items-start justify-between h-full">
          {/* Logo */}
          <Link to="/">
            <ToggleLogos isOpen={isOpen} squareLogoUrl={args.squareLogoUrl}  longLogoUrl={args.longLogoUrl}/>
          </Link>

          {/* List of pages */}
          {isOpen ? <PageList categories={args.categories} textColor={args.textColour}/> : null}

          {isOpen ? user?.name?.split("@")[0] : null}
          {isOpen
              ? <button
                  onClick={() => logout()}
                  className="logout-button"
                  >
                Logout
                </button>
              : null
          }

          {/* Toggle arrows */}
          <button onClick={toggleSidebar}>
            <img
              src="/arrows.png"
              height={30}
              width={30}
              alt="arrows"
              className={`transition-all transform duration-300 ${
                isOpen ? "rotate-0" : "rotate-180"
              }`}
            />
          </button>
        </div>
      </div>
      <main className={isOpen ? "ml-40" : "ml-20"}>{args.children}</main>
    </div>
  );
}

export default Sidebar;
