import React from "react";
import CircularProgress from '@mui/material/CircularProgress';


export default function LoadingPage() {
    let logo: string = '/superHostLongLogo.png'
    let height =  "60px"

    // TODO
    if (window.location.origin.includes("abinbev") || window.location.origin.includes("alken-maes") ) {
        logo  = '/billyLong.png'
        height = "80px"
    }

  return (
    <main style={{"height": "100vh"}}>
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', height: "100%"
        }}>
            <div>
                <img
                    src={logo}
                    alt=""
                    className="cursor-pointer"
                    style={{ height: height }}
                />
                <div style={{
                    marginTop: "15px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                 }}>
                    Loading...
                    <div style={{marginLeft: "5px"}}>
                        <CircularProgress color="primary"/>
                    </div>
                </div>
            </div>
        </div>
    </main>
  );
}
