import React, {useEffect, useState} from "react";
import DashboardPage, {LuzmoConfig} from "../pages/dashboard/DashboardPage";
import {useAuth0} from "@auth0/auth0-react";
import LoadingPage from "../pages/LoadingPage";
import Sidebar from "./sidebar/Sidebar";
import {AuthCallbackResponse} from "./model/AuthCallbackResponse";
import {plainToClass} from "class-transformer";
import {DashboardCategoryConfig, DashboardPageConfig} from "./model/dashboard";

interface PageArgs {
    page: string;
}

class PageCategory {
    category: DashboardCategoryConfig;
    page: DashboardPageConfig;

    constructor(category: DashboardCategoryConfig, page: DashboardPageConfig) {
        this.category = category;
        this.page = page;
    }
}


export default function Page(props: PageArgs) {
    const [luzmoConfig, setLuzmoConfig] = useState<LuzmoConfig | null>(null);
    const [config, setConfig] = useState<AuthCallbackResponse | null>(null);
    const {loginWithRedirect, isAuthenticated, getAccessTokenSilently, user} = useAuth0();

    useEffect(() => {
        function navigateToDashboard(data: AuthCallbackResponse) {
            const selectedPage = data.categories
                .filter((category) => category.pages)
                .flatMap((category) => category.pages.map((p) => new PageCategory(category, p)))
                .find(p => `${p.category.path}/${p.page.slug}` === props.page);

            if (selectedPage) {
                setLuzmoConfig({
                    dashboardSlug: selectedPage.page.slug,
                    authToken: data.token,
                    authKey: data.key,
                    title: selectedPage.page.title,
                    role: data.role
                });
            } else {

                // TODO what if there is no category
                setLuzmoConfig({
                    dashboardSlug: data.categories[0].pages[0].slug,
                    authToken: data.token,
                    authKey: data.key,
                    title: data.categories[0].pages[0].title,
                    role: data.role
                });
            }
        }

        getAccessTokenSilently().then(token => {
            if (config) {
                if (config.expiry > new Date()) {
                    navigateToDashboard(config)
                    return
                }
            }

            if (user) {
                // TODO do this better
                var s = `https://dashboard-auth.superhost.app/auth_callback?host=${window.location.origin.replace("https://", "")}`
                if (window.location.origin.includes("localhost")) {
                    s = `https://dashboard-auth.superhost.app/auth_callback?host=bavet.superhost.app`
                }
                window.fetch(s, {
                    method: 'POST',
                    body: JSON.stringify({
                        name: user?.name,
                        email: user?.email,
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => plainToClass(AuthCallbackResponse, response.json()))
                    .then((data) => {
                        setConfig(data);
                        navigateToDashboard(data);
                    });
            }
        }).catch(() => loginWithRedirect().catch(console.error));

        //
    }, [props.page, loginWithRedirect, isAuthenticated, user, getAccessTokenSilently, config]);

    if (luzmoConfig == null || config == null) {
        return <LoadingPage/>;
    } else {
        console.log(config.branding.long_logo_url)
        console.log(config.branding.square_logo_url)
        return <Sidebar categories={config.categories} squareLogoUrl={config.branding.square_logo_url} longLogoUrl={config.branding.long_logo_url} textColour={config.branding.text_colour}>
            <DashboardPage luzmo={luzmoConfig} color={config.branding.text_colour}/>
        </Sidebar>
    }

};
