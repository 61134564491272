import {LuzmoDashboardComponent} from "@luzmo/react-embed";
import "./DashboardPage.css"
// import {DashboardEditMode} from "@luzmo/shared-embed/src/lib/models/common.model";

export interface LuzmoConfig {
    authKey: string;
    authToken: string
    dashboardSlug: string
    title: string
    role: string
}

interface DashboardPageArgs {
    luzmo: LuzmoConfig
    color: string
}


export default function DashboardPage(args: DashboardPageArgs) {
  return (
    <main className="min-h-screen">
        <div className="luzmo-padding">
            <LuzmoDashboardComponent
                authKey={args.luzmo.authKey}
                authToken={args.luzmo.authToken}
                dashboardSlug={args.luzmo.dashboardSlug}
                title={args.luzmo.title}
                loaderSpinnerColor={args.color}
                // mainColor="666666"
                loaderBackground="#f2f2f2"
            ></LuzmoDashboardComponent>
        </div>
    </main>
  );
}
