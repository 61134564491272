import React from "react";

interface ToggleLogosArgs {
    isOpen: boolean;
    longLogoUrl: string
    squareLogoUrl: string

}

export default function ToggleLogos(props: ToggleLogosArgs) {
  return (
    <div className="flex items-center justify-center">
      {props.isOpen ? (
        <img src={props.longLogoUrl} alt="" className="h-auto" />
      ) : (
        <img src={props.squareLogoUrl} alt="" className="h-auto" />
      )}
    </div>
  );
}

